hr {
  position: relative;
  margin: rem(25px) auto;
  max-width: 100%;

  &:before {
    @include pseudo;
    top: rem(1px);
    left: 0;
    right: 0;
    height: rem(1px);
    max-width: 100%;
    background: $hr-background2;
  }
}
