.text {
  
  .text-heading {
    font-size: rem(36px);
    line-height: 1;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
  }
  
  .text-image-subheading {
    font-size: rem(21px);
    line-height: 1;
    font-style: italic;
    margin: rem(10px) 0 0;
  }
  
  .text-paragraphs {
    font-size: rem(21px);
    line-height: rem(35px);
    margin-top: rem(30px);
    
    p {
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}