html,
body {
  font-family: $primary-font-family;
  font-size: $base-font-size;
  font-weight: $primary-font-weight;
  color: $primary-font-color;
  line-height: $primary-line-height;
  text-rendering: $text-render-mode;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $heading-font-color;
  line-height: $heading-line-height;
  margin: $heading-font-margin;
}

h1 {
  font-size: $h1-size;
  color: $off-white;
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4 {
  font-size: $h4-size;
}

h5 {
  font-size: $h5-size;
}

h6 {
  font-size: $h6-size;
}

p {
  margin: $primary-font-margin;
}

small {
  font-size: $small-font-size;
}

a {
  margin: $link-font-margin;
  color: $link-font-color;
  text-decoration-skip: auto;
  transition: all $base-duration $base-timing;

  &:visited {
    color: $link-font-color;
  }

  &:hover,
  &:active {
    color: $link-font-color-hover;
  }

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
    box-shadow: $focus-box-shadow;
  }
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  margin: 0;
}

dd {
  margin: 0;
}

hr {
  border: none;
  margin: 0 auto;
  width: $hr-width;
  height: $hr-height;
  background: $hr-background;
}
