.container {
  @extend %container;
}

.container-xs {
  @extend %container;
  @extend %container-xs;
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.7);
  z-index: 90;
  pointer-events: none;
  opacity: 0;
  transition: $base-transition;
}

body {
  padding-top: $nav-bar-height;

  &.nav-open,
  &.video-lightbox-show {
    .page-overlay {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.spacer {
  height: $base-spacing * 2;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
