nav {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $nav-bar-height;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: $base-transition;

  &.nav-up {
    position: fixed;
    top: 0 - $nav-bar-height;
  }

  &.nav-down {
    position: fixed;
    top: 0;
  }

  .nav-logo-link {
    z-index: 110;

    .nav-logo {
      width: rem(188px);
      max-width: rem(188px);
    }
  }

  .nav-toggle {
    position: absolute;
    right: rem(20px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(36px);
    height: rem(26px);

    .nav-toggle-icon {
      position: relative;
      display: inline-block;
      width: 100%;
      height: rem(4px);
      background-color: $socialgram-blue;
      transition: $base-transition;

      &:before,
      &:after {
        @include pseudo;
        left: 0;
        width: rem(36px);
        height: rem(4px);
        background-color: $socialgram-blue;
        transition: $base-transition;
        z-index: 1;
      }

      &:before {
        top: rem(-11px);
      }

      &:after {
        bottom: rem(-11px);
      }
    }
  }

  .nav-menu {
    background-color: $white;
    position: fixed;
    padding-top: $nav-bar-height;
    top: 0;
    right: 0 - $nav-menu-width;
    bottom: 0;
    width: $nav-menu-width;
    transition: $base-transition;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include phone {
      right: -100vw;
      width: 100vw;
    }

    .nav-link,
    .nav-link:visited {
      display: block;
      font-family: $font-proxima-nova;
      font-size: rem(36px);
      line-height: 1;
      font-weight: 900;
      color: $dark-grey;
      margin: rem(22px) 0;

      &:hover,
      &:focus {
        color: lighten($dark-grey, 10%);
      }

      &:last-of-type {
        margin-bottom: rem(80px);
      }
    }
  }
}

body {
  &.nav-open {
    .nav-menu {
      right: 0;
    }

    .nav-toggle {
      .nav-toggle-icon {
        background-color: $white;

        &:before {
          top: 0;
          transform: rotate(-45deg);
        }

        &:after {
          bottom: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
