.work-listing {

  .work-listing-row {
    @extend %row;
    @extend %row-wrap;

    .work-listing-col {
      @extend %col;
      @extend %col-6;
      @extend %col-phone;
      padding-top: $grid-gutter;
      padding-bottom: $grid-gutter;

      .work-listing-link,
      .work-listing-link:visited {
        display: flex;
        flex-direction: column;
        color: $dark-grey;

        &:hover,
        &:focus {
          color: lighten($dark-grey, 10%);
        }
      }

      .work-listing-heading {
        font-size: rem(24px);
        line-height: 1;
        font-weight: 700;
        margin: 0;
        padding: rem(20px) 0 0;
      }

      .work-listing-subheading {
        font-size: rem(21px);
        line-height: 1;
        padding: rem(15px) 0 rem(5px);
      }
    }
  }

  .show-more-wrapper {
    margin-top: rem(30px);
    background-color: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(85px);
    max-height: rem(85px);
    overflow: hidden;
    transition: $base-transition;
    opacity: 1;

    &.hide {
      margin-top: 0;
      max-height: 0;
      opacity: 0;
    }

    .show-more {
      font-family: $font-proxima-nova;
      font-size: rem(24px);
      line-height: rem(35px);
      font-weight: 700;
      text-transform: uppercase;
      color: $white;
      transition: $base-transition;

      &:hover {
        color: $light-grey;
      }
    }
  }
}
