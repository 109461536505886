.three-images {

  .three-images-row {
    @extend %row;
    @extend %row-wrap;

    &.large-image-right {
      @extend %row-reverse;
    }

    .large-image-col {
      @extend %col;
      @extend %col-6;
      @extend %col-phone;

      &:first-child {
        @include phone {
          margin-bottom: $base-spacing * 2;
        }
      }

      .image-large {
        @include full-background;
        @include responsive-ratio(515, 682, true);
        height: 100%;
      }
    }

    .smaller-images-col {
      @extend %col;
      @extend %col-6;
      @extend %col-phone;

      &:first-child {
        @include phone {
          margin-bottom: $base-spacing * 2;
        }
      }

      .image-small {
        @include full-background;
        @include responsive-ratio(515, 326, true);

        &:not(:first-child) {
          margin-top: $base-spacing * 2;
        }
      }
    }
  }
}
