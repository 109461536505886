main {
   [class^="section-"] {

  }
}

.social-links {
  margin-top: rem(40px);
  
  .social-link,
  .social-link:visited {
    font-size: rem(28px);
    color: $socialgram-blue;
    margin: 0 rem(26px);
    
    &:hover,
    &:focus {
      color: lighten($socialgram-blue, 15%);
    }
  }
}