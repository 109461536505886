footer {
  background-color: $dark-grey;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-logo {
    margin-top: rem(20px);
    width: rem(188px);
    max-width: rem(188px);
  }

  .footer-links {
    margin-bottom: rem(14px);
    font-family: $font-proxima-nova;
    font-size: rem(14px);
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: $socialgram-blue;

    .footer-link,
    .footer-link:visited {
      color: $socialgram-blue;

      &:hover,
      &:focus {
        color: lighten($socialgram-blue, 15%);
      }
    }
  }

  .copyright {
    font-family: $font-proxima-nova;
    font-size: rem(10px);
    line-height: 1;
    text-transform: uppercase;
    color: $white;
  }
}
