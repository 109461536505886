.locations {
  padding-bottom: $base-spacing * 4;

  .locations-row {
    @extend %row;
    @extend %row-wrap;

    .locations-col {
      @extend %col;
      @extend %col-6;
      @extend %col-phone;

      &:not(:last-child) {
        @include phone {
          margin-bottom: $base-spacing * 2;
        }
      }

      .location-image {
        position: relative;
        margin-bottom: rem(20px);

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $overlay-color;
        }

        .location-short-name {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          font-size: rem(70px);
          text-transform: uppercase;
          line-height: 1;
          font-weight: 900;
          text-align: center;
          color: transparent;
          -webkit-text-stroke: rem(1px) $white;
          // text-shadow: rem(-1px) rem(-1px) 0 $white, rem(1px) rem(-1px) 0 $white, rem(-1px) rem(1px) 0 $white, rem(1px) rem(1px) 0 $white;
        }
      }

      .location-name {
        font-size: rem(24px);
        line-height: rem(35px);
        text-transform: uppercase;
      }

      .location-address {
        font-size: rem(21px);
        line-height: rem(32px);
      }

      .location-email {
        font-size: rem(21px);
        line-height: rem(32px);
        color: $primary-font-color;
      }
    }
  }
}
