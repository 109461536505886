.services {
  background-color: $dark-grey;
  padding: rem(70px) 0 0;
  position: relative;

  &:before {
    @include pseudo;
    bottom: 0;
    left: 0;
    right: 0;
    height: rem(180px);
    background-color: $white;
  }

  .services-heading {
    margin: 0 auto;
    max-width: rem(770px);
    font-size: rem(70px);
    text-transform: uppercase;
    line-height: rem(48px);
    font-weight: 900;
    text-align: center;
    margin-bottom: rem(70px);

    @include phone {
      font-size: rem(42px);
      line-height: rem(30px);
    }

    .outline {
      display: block;
      color: transparent;
      -webkit-text-stroke: rem(1px) $white;
      // text-shadow: rem(-1px) rem(-1px) 0 $white, rem(1px) rem(-1px) 0 $white, rem(-1px) rem(1px) 0 $white, rem(1px) rem(1px) 0 $white;
    }
  }

  .services-slider {
    overflow: hidden;
    z-index: 1;
    position: relative;
    padding-bottom: rem(110px);

    .service-slide {
      position: relative;
      display: inline-block;
      @include responsive-ratio(378, 382, true);
      width: calc(33.3333% - #{rem(30px)});
      margin-right: rem(30px);

      @include tablet-max {
        width: calc(50% - #{rem(30px)});
      }

      @include phone {
        width: calc(100% - #{rem(30px)});
      }

      &.blue {
        background-color: $socialgram-blue;
        color: $white;

        .service-heading {
          color: $white;
        }

        .service-line {
          background-color: $dark-grey;
        }
      }

      &.grey {
        background-color: $grey;
        color: $white;

        .service-heading {
          color: $white;
        }

        .service-line {
          background-color: $yellow;
        }
      }

      &.yellow {
        background-color: $yellow;
        color: $dark-grey;

        .service-heading {
          color: $dark-grey;
        }

        .service-line {
          background-color: $white;
        }
      }

      .service-heading {
        position: absolute;
        top: rem(40px);
        left: rem(24px);
        right: rem(24px);
        z-index: 1;
        font-size: rem(40px);
        line-height: rem(46px);
        text-transform: uppercase;

        @include lg-tablet-max {
          font-size: rem(34px);
          line-height: rem(42px);
        }

        @include phone {
          top: rem(32px);
          font-size: rem(38px);
          line-height: rem(44px);
        }
      }

      .service-line {
        position: absolute;
        top: 43.5%;
        left: rem(15px);
        right: rem(70px);
        height: rem(2px);
      }

      .service-info {
        position: absolute;
        bottom: rem(22px);
        left: rem(24px);
        right: rem(24px);
        z-index: 1;
        font-size: rem(21px);
        line-height: rem(36px);
        font-weight: 600;

        @include lg-tablet-max {
          bottom: rem(20px);
          font-size: rem(20px);
          line-height: rem(34px);
        }

        @include phone {
          font-size: rem(18px);
          line-height: rem(32px);
        }
      }
    }

    .flickity-page-dots {
      bottom: 0;
      height: rem(110px);
      display: flex;
      align-items: center;
      justify-content: center;

      .dot {
        transition: $base-transition;
        width: rem(125px);
        height: rem(5px);
        border-radius: 0;
        background-color: $light-grey;
        opacity: 1;
        margin: 0;

        &.is-selected {
          background-color: $socialgram-blue;
        }
      }
    }
  }
}
