.intro-hero {
  background-color: $dark-grey;
  text-align: center;
  padding: rem(110px) 0 rem(70px);

  @include phone {
    padding: rem(60px) 0 rem(60px);
  }

  .hero-heading {
    margin: 0 auto;
    max-width: rem(570px);
    font-size: rem(70px);
    text-transform: uppercase;
    line-height: rem(48px);
    font-weight: 900;

    @include phone {
      font-size: rem(42px);
      line-height: rem(30px);
    }

    .outline {
      display: block;
      color: transparent;
      -webkit-text-stroke: rem(1px) $white;
      // text-shadow: rem(-1px) rem(-1px) 0 $white, rem(1px) rem(-1px) 0 $white, rem(-1px) rem(1px) 0 $white, rem(1px) rem(1px) 0 $white;
    }
  }

  .hero-subheading {
    @extend %container;
    @extend %container-xs;
    font-size: rem(21px);
    line-height: rem(35px);
    color: $white;

    @include phone {
      font-size: rem(18px);
      line-height: rem(32px);
    }
  }
}
