.two-images {

  .two-images-row {
    @extend %row;
    @extend %row-wrap;

    .two-images-col {
      @extend %col;
      @extend %col-6;
      @extend %col-phone;

      &:first-child {
        @include phone {
          margin-bottom: $base-spacing * 2;
        }
      }
    }
  }
}
