// Flex Row
// ==================================================
%row {
  display: flex;
  flex: 0 1 auto;
  margin: 0 ($grid-gutter * -1);

  &-wrap {
    flex-wrap: wrap;
    &::after {
      content: '';
      flex: auto;
    }
  }

  &-no-gutter {
    margin: 0;

    > %col {
      padding: 0;
    }
  }

  // &-lg-gutter {
  //   margin: 0 (($grid-gutter * 2) * -1);
  //
  //   > %col {
  //     padding: 0 ($grid-gutter * 2);
  //   }
  // }

  &-reverse {
    flex-direction: row-reverse;
  }

  // Column Distribution
  &-around {
    justify-content: space-around;
  }

  &-between {
    justify-content: space-between;
  }

  // Horizontal Alignment
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  // Vertical Alignment
  &-top {
    align-items: flex-start;
  }

  &-middle {
    align-items: center;
  }

  &-bottom {
    align-items: flex-end;
  }

  // Columns
  > %col {
    flex: 1 0 0;
    max-width: 100%;
    min-width: 0;
    padding: 0 $grid-gutter;
    position: relative;

    // Column Sizing
    // ==================================================

    // Fixed Column Class eg <div class="col fixed" style="width:100px;">
    &-fixed {

      flex: initial;
    }

    // Auto Width Columns
    &-auto {

      flex: 1 1 auto;
    }

    // Grow Based Columns
    @for $i from 2 through ($grid-cols - 1) {
      &-grow-#{$i} {

        flex-grow: $i;
      }
    }

    // Percent Based Columns
    @for $i from 1 through $grid-cols {
      &-#{$i} {
        flex-basis: (100% / $grid-cols) * $i;
        max-width: (100% / $grid-cols) * $i;
      }
    }

    // Responsive
    &-phone {
      @media only screen and (max-width: ($tablet - rem(1px))) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &-tablet {
      @media only screen and (max-width: ($lg-tablet - rem(1px))) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &-lg-tablet {
      @media only screen and (max-width: ($laptop - rem(1px))) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &-laptop {
      @media only screen and (max-width: ($desktop - rem(1px))) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &-desktop {
      flex-basis: 100%;
      max-width: 100%;
    }

    // Reordering Columns
    &-first {
      order: -1;
    }

    &-last {
      order: 1;
    }

    // Column Vertical Alignment Classes
    &-start {
      align-self: flex-start;
    }

    &-center {
      align-self: center;
    }

    &-end {
      align-self: flex-end;
    }

    // Column Vertical Alignment Classes
    &-top {
      justify-self: flex-start;
    }

    &-middle {
      justify-self: center;
    }

    &-bottom {
      justify-self: flex-end;
    }

    // Column Content Alignment Classes
    &-content-start {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-content-end {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-content-top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
    }

    &-content-middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-content-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end !important;
    }

  } // col

} // row
