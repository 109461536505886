.text-content {
  padding-top: rem(80px);
  padding-bottom: rem(80px);
  
  .text-content-heading {
    font-size: rem(36px);
    line-height: 1;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: rem(50px);
  }
  
  .text-paragraphs {
    font-size: rem(21px);
    line-height: rem(35px);
  }
}