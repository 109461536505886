.featured-work {

  .featured-work-row {
    @extend %row;
    @extend %row-wrap;
    padding-top: $base-spacing;
    // padding-bottom: $base-spacing;

    .featured-work-col {
      @extend %col;
      @extend %col-6;
      @extend %col-phone;
      padding-top: $base-spacing;
      padding-bottom: $base-spacing;

      .featured-work-link,
      .featured-work-link:visited {
        display: flex;
        flex-direction: column;
        color: $dark-grey;

        &:hover,
        &:focus {
          color: lighten($dark-grey, 10%);
        }
      }

      .featured-work-heading {
        font-size: rem(24px);
        line-height: 1;
        font-weight: 700;
        margin: 0;
        padding: rem(20px) 0 0;
      }

      .featured-work-subheading {
        font-size: rem(21px);
        line-height: 1;
        padding: rem(15px) 0 rem(5px);
      }
    }
  }
}
