.full-width-image {

  .img {
    height: rem(600px);
    @include full-background;

    @include phone {
      max-height: 60vw;
    }
  }
}
