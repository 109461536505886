@function strip-unit($value) {
  @return ($value / ($value * 0 + 1));
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    $first: str-slice($string, 1, $index - 1);
    $last-slice: str-slice($string, $index + str-length($search));
    $last: str-replace($last-slice, $search, $replace);
    @return $first + $replace + $last;
  }
  @return $string;
}

$base-font-size: 16px;

@function rem($pixels, $base-size: $base-font-size) {
  $pixels: strip-unit($pixels);
  $base-size: strip-unit($base-size);
  @return ($pixels / $base-size) * 1rem;
}

@function em($pixels, $base-size: $base-font-size) {
  $pixels: strip-unit($pixels);
  $base-size: strip-unit($base-size);
  @return ($pixels / $base-size) * 1em;
}

@function pt($pixels, $base-size: $base-font-size) {
  $pixels: strip-unit($pixels);
  $base-size: strip-unit($base-size);
  @return ($pixels / $base-size) * 1pt;
}

@function pi() {
  @return 3.14159265359;
}

@function all($inputs, $pseudo: null) {
  $list: ();
  @each $input in $inputs {
    $input: unquote($input);
    $input: if($pseudo, $input + ':' + $pseudo, $input);
    $list: append($list, $input, comma);
  }
  @return $list;
}

@function power-number($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  }
  @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }
  @return $value;
}

@function color-luminance($color) {
  $color-rgb: ('red': red($color),'green': green($color),'blue': blue($color));
  @each $name, $value in $color-rgb {
    $adjusted: 0;
    $value: $value / 255;
    @if $value < 0.03928 {
      $value: $value / 12.92;
    } @else {
      $value: ($value + .055) / 1.055;
      $value: power-number($value, 2);
      $color-rgb: map-merge($color-rgb, ($name: $value));
    }
  }
  @return (map-get($color-rgb, 'red') * .2126) + (map-get($color-rgb, 'green') * .7152) + (map-get($color-rgb, 'blue') * .0722);
}

$dark-color: #212121;
$light-color: #fafafa;

@function find-color-invert($color, $dark: $dark-color, $light: $light-color) {
  @if (color-luminance($color) > 0.55) {
    @return $dark;
  } @else {
    @return $light;
  }
}

@function tint($color, $percentage: 50%) {
  @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage: 50%) {
  @return mix(#000, $color, $percentage);
}
