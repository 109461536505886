$socialgram-blue: #38b8cd;

$white:           #ffffff;
$light-grey:      #ebe8e8;
$grey:            #878787;
$dark-grey:       #333;
$black:           #000;

$yellow:          #ffec01;

$overlay-color:         rgba($black, 0.6);


$base-spacing: rem(15px);


$primary-font-family: $font-open-sans;
//$primary-font-weight: 400;

$secondary-font-family: $font-proxima-nova;

$heading-font-family: $font-proxima-nova;
$heading-font-color: $dark-grey;

$focus-outline:        none;
$focus-box-shadow:     none;


$container-max-width-xs-px: 890px;
$container-max-width-px: 1060px;



$section-padding: rem(30px) 0;





$hr-width: rem(546px);
$hr-height: rem(3px);
$hr-background: linear-gradient(to right, rgba($socialgram-blue, 0), rgba($socialgram-blue, 0.5), rgba($socialgram-blue, 0));
$hr-background2: linear-gradient(to right, rgba($socialgram-blue, 0), rgba($socialgram-blue, 1), rgba($socialgram-blue, 0));


$nav-bar-height: rem(84px);
$nav-menu-width: rem(410px);
