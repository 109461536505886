.image-sqaure {
  @include full-background;
  @include responsive-ratio(1, 1, true);

  background-size: 100%;
  transition: $base-transition;

  &:hover {
    background-size: 102%;
  }
}
