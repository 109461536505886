// Include hosted font file
@mixin font-file($font-family, $font-file, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-family: $font-family;
        src: url($font-file+'.eot');
        src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
        url($font-file+'.woff') format('woff'),
        url($font-file+'.woff2') format('woff2'),
        url($font-file+'.ttf') format('truetype'),
        url($font-file+'.svg#aller') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}