.hero {
  margin: rem(30px) 0 rem(60px);

  .hero-heading {
    margin: 0 auto;
    max-width: rem(780px);
    font-size: rem(70px);
    text-transform: uppercase;
    line-height: rem(48px);
    font-weight: 900;
    color: $socialgram-blue;
    text-align: center;

    @include phone {
      font-size: rem(42px);
      line-height: rem(30px);
    }

    .outline {
      color: transparent;
      -webkit-text-stroke: rem(1px) $socialgram-blue;
      // text-shadow: rem(-1px) rem(-1px) 0 $socialgram-blue, rem(1px) rem(-1px) 0 $socialgram-blue, rem(-1px) rem(1px) 0 $socialgram-blue, rem(1px) rem(1px) 0 $socialgram-blue;
    }
  }
}
