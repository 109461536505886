// Import any CDN fonts
// ==================================================

// https://fonts.google.com
// https://fonts.adobe.com/typekit

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400&display=swap'); // 300, 300 italic, 400


// Include any hosted fonts
// ==================================================

@include font-file('Proxima Nova', '../font/proxima-nova-regular', 400);
@include font-file('Proxima Nova', '../font/proxima-nova-bold', 700);
@include font-file('Proxima Nova', '../font/proxima-nova-extrabold', 800);
@include font-file('Proxima Nova', '../font/proxima-nova-black', 900);


// Font stacks
// ==================================================

$font-open-sans:    'Open Sans', sans-serif;
$font-proxima-nova: 'Proxima Nova', sans-serif;