header {

  .header-image {
    height: rem(600px);
    @include full-background;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include phone {
      max-height: calc(100vw - #{$nav-bar-height});
    }

    .header-image-text {
      height: rem(150px);
      background: linear-gradient(to bottom,  rgba($black, 0), rgba($black,0.70));
      color: $white;

      @include phone {
        height: rem(130px);
      }

      .header-image-heading {
        margin: rem(16px) 0 0;
        font-size: rem(50px);
        line-height: 1;
        font-weight: 900;
        color: $white;

        @include phone {
          font-size: rem(46px);
        }
      }

      .header-image-subheading {
        margin: rem(10px) 0 0;
        font-size: rem(24px);
        line-height: 1;
        font-style: italic;

        @include phone {
          font-size: rem(20px);
        }
      }
    }
  }

  .header-text {
    margin: rem(60px) 0 rem(30px);
    .header-text-heading {
      margin: 0;
      font-size: rem(36px);
      line-height: 1;
      font-weight: 900;
      color: $dark-grey;
    }

    .header-text-subheading {
      margin: rem(10px) 0 0;
      font-size: rem(21px);
      line-height: 1;
    }
  }

  .header-hero {
    margin: rem(80px) 0 rem(50px);

    @include phone {
      margin: rem(60px) 0 rem(40px);
    }

    .header-hero-heading {
      margin: 0 auto;
      max-width: rem(780px);
      font-size: rem(70px);
      text-transform: uppercase;
      line-height: rem(48px);
      font-weight: 900;
      color: $socialgram-blue;
      text-align: center;

      @include phone {
        font-size: rem(42px);
        line-height: rem(30px);
      }

      .outline {
        color: transparent;
        -webkit-text-stroke: rem(1px) $socialgram-blue;
        // text-shadow: rem(-1px) rem(-1px) 0 $socialgram-blue, rem(1px) rem(-1px) 0 $socialgram-blue, rem(-1px) rem(1px) 0 $socialgram-blue, rem(1px) rem(1px) 0 $socialgram-blue;

        &:not(.inline) {
          display: block;
        }
      }
    }
  }

  .header-video {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include overlay;
    max-height: rem(720px);
    overflow: hidden;
    position: relative;

    &:before {
      z-index: 1;
    }

    .video-preview {
      width: 100vw;
      position: relative;
      @include full-background;
      @include responsive-ratio(1920, 1080);

      @include phone {
        min-width: 124vw;
        width: 124vw;
        padding-top: 70%;
      }
    }

    .header-video-text-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .header-video-text {
        @extend %container;
        @extend %container-xs;

        .header-video-heading {
          margin: 0 auto;
          max-width: rem(550px);
          font-size: rem(70px);
          text-transform: uppercase;
          line-height: rem(48px);
          font-weight: 900;
          color: $white;
          text-align: center;

          @include lg-tablet-max {
            max-width: rem(400px);
            font-size: rem(60px);
            line-height: rem(42px);
          }

          @include phone {
            max-width: rem(320px);
            font-size: rem(36px);
            line-height: rem(26px);
          }

          .outline {
            display: block;
            color: transparent;
            -webkit-text-stroke: rem(1px) $white;
            // text-shadow: rem(-1px) rem(-1px) 0 $white, rem(1px) rem(-1px) 0 $white, rem(-1px) rem(1px) 0 $white, rem(1px) rem(1px) 0 $white;
          }
        }

        .btn-play {
          display: block;
          background-image: url('../images/play.png');
          background-repeat: no-repeat;
          margin: rem(80px) auto 0;
          height: rem(120px);
          transition: $base-transition;
          opacity: 0.8;
          background-position: center rem(10px);
          text-align: center;
          padding: rem(80px) rem(10px) rem(10px);
          text-transform: uppercase;
          font-family: $font-proxima-nova;
          font-size: rem(24px);
          line-height: 1;
          color: $white;

          @include lg-tablet-max {
            margin: rem(30px) auto 0;
          }

          @include phone {
            background-size: 40%;
            margin: rem(20px) auto 0;
            height: rem(90px);
            font-size: rem(20px);
            padding: rem(70px) rem(10px) 0;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .video-lightbox {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    @include responsive-ratio(1920, 1080,true);
    width: $container-max-width;
    max-width: calc(100vw - #{rem(60px)});
    z-index: 100;
    transition: $base-transition;
    pointer-events: none;
    opacity: 0;
  }
}

body {
  &.video-lightbox-show {

    nav {
      z-index: 50;
    }

    .video-lightbox {
      pointer-events: auto;
      opacity: 1;
    }
  }
}
